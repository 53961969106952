import React from "react";
import { Col } from "reactstrap";
import banner_video from "../../assets/banners/video-bg.mp4";
import poster from "../../assets/banners/poster.jpg";

const HeaderBanner = () => {
  return (
    <div id="videoWrapper" className="banner1">
      <div id="fullScreenDiv">
        <video
          id="video"
          src={banner_video}
          type="video / mp4"
          autoPlay={true}
          loop={true}
          muted={true}
          preload="none"
          playsInline
          poster={poster}
          // role="presentation"
          className="blur"
        />

        <div id="fullScreenDiv-1" />

        <div className="overlay justify-content-left">
          <Col md="8" className="align-self-left text-left">
            <h1 className="title-light">
              Résponsabiliser les entreprises grâce à des solutions IT
              innovantes.
            </h1>

            <h4 className="subtitle font-light">
              <br />
              <strong>
                Nous mettons les entreprises en parfaite harmonie avec leurs
                besoins technologiques.
              </strong>
            </h4>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
