/* eslint-disable */
import React from "react";
import { Container, Row, Col } from "reactstrap";
// import { HeaderComponent } from "../views/custom-components/sections/headercomponent.jsx";
import Header from "../header/header.jsx";
const Footer = () => {
  var currentTime = new Date();
  var year = currentTime.getFullYear();
  return (
    <div className="footer4 b-t spacer">
      <Container>
        <Row>
          <Col lg="3" md="6" className="m-b-30" align="center">
            <h5 className="m-b-20">
              <strong>Adresse</strong>
            </h5>
            <p>Tevragh Zein, Nouakchott</p>
          </Col>
          <Col lg="3" md="6" className="m-b-30" align="center">
            <h5 className="m-b-20">
              <strong>Téléphone</strong>
            </h5>
            <p>+222 20 14 15 00</p>
          </Col>
          <Col lg="3" md="6" className="m-b-30" align="center">
            <h5 className="m-b-20">
              <strong> Email</strong>
            </h5>
            <p>contact@izlytech.com</p>
          </Col>
          <Col lg="3" md="6" className="m-b-30" align="center">
            <h5 className="m-b-20">
              {" "}
              <strong>Réseaux sociaux</strong>
            </h5>
            <div className="round-social light" paddingleft="0">
              <a
                href="https://m.facebook.com/IZLY-TECH-101575526013852/"
                target="_blank"
                className="link"
              >
                <i className="fa fa-facebook"></i>
              </a>
              {/* <a href="#" className="link">
                <i className="fa fa-twitter"></i>
              </a> */}

              <a
                href="https://www.linkedin.com/company/izlytech/"
                target="_blank"
                className="link"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </Col>
        </Row>
        <div className="f4-bottom-bar">
          <Row>
            <Col md="12">
              <div className="font-14">
                <div className="copyright ">
                  <i className="fa fa-copyright"></i> {year} IzlyTech - Tous les
                  droits réservés
                </div>
                {/* <div className="links ml-auto m-t-10 m-b-10">
                  <a href="#" className="p-10 p-l-0">
                    Terms of Use
                  </a>
                  <a href="#" className="p-10">
                    Legal Disclaimer
                  </a>
                  <a href="#" className="p-10">
                    Privacy Policy
                  </a>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default Footer;
