/* eslint-disable */
import React from "react";

import HeaderComponent from "../../views/components//headercomponent";

const Header = () => {
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/

  return <HeaderComponent />;
};
export default Header;
