/* eslint-disable */
import React, { useState } from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
// import ReadMore from "react-readmore";

import img1 from "../../assets/images/Logo Files/spacer-bg.jpg";

const FeatureComponent = () => {
  const [showMore, setShowMore] = useState(false);
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);

  const text_1 = `Nous offrons des solutions instantanées et des outils  technologiques dont votre entreprise a besoin. Nos ressources et notre expertise sont soigneusement orientées vers la réussite grâce à des évaluations de haute qualité. Nous nous attachons à fournir des solutions instantanées, riches en valeur et rentables, tout en garantissant un service client de qualité.
`;

  const text_2 = `Nous adoptons une méthodologie éprouvée, qui permet aux entreprises de rester agiles et de suivre les dernières tendances. Sur le marché concurrentiel d'aujourd'hui, l'automatisation et les méthodologies de gestion associées sont cruciales pour garantir une meilleure efficacité des opérations de votre entreprise.`;

  const text_3 = `Nous formons une équipe expérimentée et talentueuse qui a fait ses preuves en matière de résultats positifs. Les personnes qui partagent nos idées se motivent mutuellement pour apporter des idées novatrices, que nous exploitons pour maximiser l'efficacité des solutions proposées.`;

  return (
    <div>
      <div className="spacer" id="aboutus">
        <Container>
          <Row className="justify-content-center">
            <Col md="10" className="text-center">
              <h2 className="title">À Propos</h2>
              <h6 className="subtitle">
                IzlyTech est une SSII dont le siège est en Mauritanie, élaborée
                avec la conviction que la technologie peut transformer votre
                entreprise, quelle que soit sa taille. Nos services comprennent
                la numérisation et l'expertise technique pour permettre aux
                entreprises d'atteindre leurs objectifs. Nous proposons une
                gamme complète de services en réseau et de compétences
                informatiques pour répondre aux besoins technologiques de
                diverses organisations. En plus, nous promettons de rationaliser
                votre accès à une technologie adaptée aux besoins de votre
                entreprise.
              </h6>
            </Col>
          </Row>
          <Row className="m-t-40">
            <Col md="4" className="wrap-feature4-box">
              <Card>
                <CardBody>
                  <div className="icon-round bg-light-info">
                    <i className="fa fa-star"></i>
                  </div>
                  <h5 className="font-medium">Solutions instantanées</h5>
                  <p className="m-t-20">
                    {showMore ? text_1 : `${text_1.substring(0, 250)}`}{" "}
                    <a
                      onClick={function (e) {
                        e.preventDefault();
                        setShowMore(!showMore);
                      }}
                      className="linking text-themecolor"
                      href="#"
                    >
                      {showMore ? "" : "... voir plus"}
                      {/* Lire plus<i className="ti-arrow-down"> */}
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="wrap-feature4-box">
              <Card>
                <CardBody>
                  <div className="icon-round bg-light-info">
                    <i className="fa fa-check-circle"></i>
                  </div>
                  <h5 className="font-medium">Techniques professionnelles</h5>
                  <p className="m-t-20">
                    {showMore1 ? text_2 : `${text_2.substring(0, 250)}`}{" "}
                    <a
                      onClick={function (e) {
                        e.preventDefault();
                        setShowMore1(!showMore1);
                      }}
                      className="linking text-themecolor"
                      href="#"
                    >
                      {showMore1 ? "" : "... voir plus"}
                      {/* Lire plus<i className="ti-arrow-down"> */}
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md="4" className="wrap-feature4-box">
              <Card>
                <CardBody>
                  <div className="icon-round bg-light-info">
                    <i className="fa fa-group"></i>
                  </div>
                  <h5 className="font-medium">Équipe expérimentée</h5>
                  <p className="m-t-20">
                    {text_3}
                    {/* {showMore2 ? text_3 : `${text_3.substring(0, 400)}`}{" "} */}

                    {/* Lire plus<i className="ti-arrow-down"> */}
                    {/* </a> */}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default FeatureComponent;
